import React from "react"
import Header from "../../Header"
import cmamedianrent from "../../images/cmamedianrent.png"
import detroittowindsor from "../../images/detroittowindsor.png"
import feedingcow from "../../images/feedingcow.png"
import walktime from "../../images/walktimes.png"
import foxtheatre from "../../images/FoxTheatre.jpg"
import maplefesitval from "../../images/maplefesitval.png"
import { Link } from "gatsby";

export default function RemoteInWindsor() {
    return (
        <>
            <Header noog={true} title="Blog - Working Remote in Windsor"
                description="Why you should work remotely in Windsor, ON"
                image={"https://johnhaldeman.ca" + walktime}
            ></Header>
            <p style={{"textAlign": "right"}}>Posted May 26, 2020</p>
            <h3>Working Remote in Windsor, ON</h3>
            <p>
                The COVID-19 pandemic has turned many people into remote workers – perhaps permanently.
                If your job has changed to allow you to work remotely, or you are currently a remote worker,
                this post is for you. Being able to live anywhere has many advantages. I’ll contend that you
                should pick Windsor, Ontario as your home base. After all, I did nine years ago when I
                first became a remote worker, and I have no intention of leaving. I came for the cost of
                living reductions, I stayed because it's a great city in a great area.
                </p>
            <h4>Cost of Living</h4>
            <p>
                The first advantage is in the cost of living. It is difficult getting detailed data on all
                Canadian Census Metropolitan Areas (CMAs) for general household expenses, but anecdotal evidence
                and informal surveys indicate that Windsor’s cost of living is relatively quite low. In terms
                of housing costs at least, there is some good data. The Canada Mortgage and Housing
                Corporation (CMHC) keeps track of a variety of housing cost measures. A popular one used in comparisons is the median
                rental cost for a two-bedroom apartment. Looking at the major cities along the 401 corridor
                between Windsor and Ottawa, Windsor is the most affordable. This lower cost is also reflected
                in the purchase prices of residential properties.
            </p>
            <a href={cmamedianrent}><img src={cmamedianrent} width="60%" className="center"></img></a>
            <p className="img-caption">Median rent for a 2-bedroom apartment along the 401 Corridor <br />
                Source: <a href="https://www03.cmhc-schl.gc.ca/hmip-pimh/#Compare/2640/3/Windsor%20CMA/L2htaXAtcGltaC9lbi9Db21wYXJlP3RvcGljPTIwMyZnZW9ncmFwaGllcyU1QjAlNUQuR2VvZ3JhcGh5SWQ9MjY0MCZnZW9ncmFwaGllcyU1QjAlNUQuR2VvZ3JhcGh5TmFtZT1XaW5kc29yJmdlb2dyYXBoaWVzJTVCMCU1RC5HZW9ncmFwaHlUeXBlPU1ldHJvcG9saXRhbk1ham9yQXJlYSZnZW9ncmFwaGllcyU1QjElNUQuR2VvZ3JhcGh5SWQ9MDk1MCZnZW9ncmFwaGllcyU1QjElNUQuR2VvZ3JhcGh5TmFtZT1Mb25kb24mZ2VvZ3JhcGhpZXMlNUIxJTVELkdlb2dyYXBoeVR5cGU9TWV0cm9wb2xpdGFuTWFqb3JBcmVhJmdlb2dyYXBoaWVzJTVCMiU1RC5HZW9ncmFwaHlJZD0wODUwJmdlb2dyYXBoaWVzJTVCMiU1RC5HZW9ncmFwaHlOYW1lPUtpdGNoZW5lciUyMC0lMjBDYW1icmlkZ2UlMjAtJTIwV2F0ZXJsb28mZ2VvZ3JhcGhpZXMlNUIyJTVELkdlb2dyYXBoeVR5cGU9TWV0cm9wb2xpdGFuTWFqb3JBcmVhJmdlb2dyYXBoaWVzJTVCMyU1RC5HZW9ncmFwaHlJZD0yMjcwJmdlb2dyYXBoaWVzJTVCMyU1RC5HZW9ncmFwaHlOYW1lPVRvcm9udG8mZ2VvZ3JhcGhpZXMlNUIzJTVELkdlb2dyYXBoeVR5cGU9TWV0cm9wb2xpdGFuTWFqb3JBcmVhJmdlb2dyYXBoaWVzJTVCNCU1RC5HZW9ncmFwaHlJZD0xMjUwJmdlb2dyYXBoaWVzJTVCNCU1RC5HZW9ncmFwaHlOYW1lPU9zaGF3YSZnZW9ncmFwaGllcyU1QjQlNUQuR2VvZ3JhcGh5VHlwZT1NZXRyb3BvbGl0YW5NYWpvckFyZWEmZ2VvZ3JhcGhpZXMlNUI1JTVELkdlb2dyYXBoeUlkPTA3MDAmZ2VvZ3JhcGhpZXMlNUI1JTVELkdlb2dyYXBoeU5hbWU9S2luZ3N0b24mZ2VvZ3JhcGhpZXMlNUI1JTVELkdlb2dyYXBoeVR5cGU9TWV0cm9wb2xpdGFuTWFqb3JBcmVhJmdlb2dyYXBoaWVzJTVCNiU1RC5HZW9ncmFwaHlJZD0xMjY1Jmdlb2dyYXBoaWVzJTVCNiU1RC5HZW9ncmFwaHlOYW1lPU90dGF3YSZnZW9ncmFwaGllcyU1QjYlNUQuR2VvZ3JhcGh5VHlwZT1NZXRyb3BvbGl0YW5NYWpvckFyZWE%253D">
                    CMHC Housing Market Information Portal
                </a>
            </p>
            <p>
                Seeing those numbers you might think that there is a tradeoff. Sure, it costs less to live in
                Windsor, but everyone knows that a place like the Greater Toronto Area (GTA) provides more
                amenities. Or does it? We’ll come to that, but first let’s talk about job market access.
            </p>
            <h4>The US Labour Market for Remote Workers</h4>
            <p>
                One of the most overlooked, but extremely helpful, things about Windsor for remote workers are the
                institutions in the city built around working for US companies. According to a Workforce
                Windsor-Essex <a href="https://www.workforcewindsoressex.com/wp-content/uploads/2017/12/Cross-Border-Employment-Report.pdf">report</a> from
                2017, approximately six thousand people live in Windsor and work across the border. Those
                workers need immigration lawyers and cross-border tax services to help navigate the new jurisdiction.
            </p>
            <p>
                A remote worker can relatively easily and inexpensively expand their search for new opportunities to
                include the US with the help of these services, provided they are employed in the qualifying professions.
                In addition, communities such as <a href="http://hackf.org">Hackforge</a> and
                the <a href="https://www.meetup.com/GDG-Windsor/">Windsor Google Developers Group</a> can
                provide personal connections to workers currently working remote for US companies. These workers are the
                exception in most Canadian cities whereas in Windsor they are common.
            </p>
            <h4>Big City Amenities</h4>
            <p>
                So, what about recreation and services? Sure, Windsor is cheap, but you may think it cannot offer everything
                you’re looking for in a city. Many people don’t live in a city like Toronto just to be close to work but 
                because of the services available there. Things like a major international airport and cultural
                activities come to mind. If that is what you think you gain by spending about $500/month more
                in housing costs to live in Toronto, here’s an introduction to Detroit.
            </p>
            <p>
                Detroit!?!? Surely the downtrodden city of less than 700 thousand people can’t offer what Toronto has.
                In fact, it can, and does. Detroit is the center of a large conglomeration of municipalities in South
                East Michigan consisting of over four million people. Imagine the GTA, but with the most affluent and
                productive parts of the metropolitan area being Hamilton, Brampton, and Mississauga rather than Toronto
                proper. That is what Detroit is like. As such, you get all the amenities that a large metropolitan area
                affords.
            </p>
            <p>
                For example, there is a major international airport, one of the biggest in the US. Detroit Metropolitan
                Airport (DTW) served nearly 37 million passengers in 2019 – ten million more than Vancouver
                International Airport, the second largest airport in Canada. In fact, DTW served more passengers
                than Toronto Pearson International Airport as recently as 2011, although Pearson now serves a
                larger number due to recent growth. It may also surprise you to know that you can get to DTW
                from Windsor in under forty minutes, even from Windsor’s outskirts and having to go through the border:
            </p>
            <a href={detroittowindsor}><img src={detroittowindsor} className="center"></img></a>
            <p className="img-caption">The 35 Minute Drive from Windsor's Airport to Detroit's according to Google Maps
            </p>
            <p>
                DTW is a great hub to all parts of the US and many international destinations, but Windsor also has a
                small airport (YQG) with daily flights to Toronto on Porter and Air Canada. If you need to travel quickly from Windsor to Toronto,
                there’s cheap parking, security line-ups that never seem to be longer than 5-10 minutes
                at the worst of times, and a 20 metre walk to the plane. Windsor also has a VIA rail connection all the way to
                Montreal, which is my preferred way to get to Toronto.
            </p>
            <p>
                Now, if you’re looking for culture, Detroit has that too. Just one example close to my heart is musical theater.
                In its 2018-2019 season, <a href="https://www.broadwayindetroit.com/">Broadway in Detroit</a>, the largest
                musical theater company in Detroit, produced eleven
                world class shows including the Book of Mormon and Hamilton. If sports is more your thing, Detroit has NFL,
                NHL, and NBA teams. It even has an annual Indycar formula one grand prix on Belle Island. The point is, if
                you have a service in a large city that you cannot find in Windsor, you’ll likely be able to find it in
                Detroit – just a short trip over the bridge or through the tunnel.
            </p>
            <a href={foxtheatre}><img src={foxtheatre} width="80%" className="center"></img></a>
            <p className="img-caption">The Interior of the Fox Theater in Detroit - One Heck of a Performance Venue</p>
            <h4>The County and Family Traditions</h4>
            <p>
                Aside from the city, Windsor is part of a productive agricultural and wine area (19 wineries) called Essex County.
                Picking apples at a local orchard and buying their cider has become a family tradition. Our favourite is <a href="http://www.wagnerorchards.com/">Wagner
                Orchards </a>because the cider is excellent, but mostly because there are some appreciative cows next to the orchard
                that enjoy snacking on the kids' apple cores. 
            </p>
            <a href={feedingcow}><img width="80%" src={feedingcow} className="center"></img></a>
            <p className="img-caption">Carefully Feeding a Downed Apple to a Cow at Wagner Orchards</p>
            <p>
                There are also a variety of festivals and events throughout the county.
                One we try to go to every year is the Maple Festival at the <a href="https://essexregionconservation.ca/location/john-r-park-homestead/">
                    John R. Park Homestead</a>. Historical
                interpretation and activities, a great view, local food trucks, and the best part: rolling taffy made from freshly simmered maple syrup poured over snow.
            </p>
            <a href={maplefesitval}><img width="80%" src={maplefesitval} className="center"></img></a>
            <p className="img-caption">Rolling Taffy</p>

            <h4>Transit Times to Daily Conveniences</h4>
            <p>
                Remote workers still need to leave the house. They may have zero-minute work commutes (a great perk),
                but they still need to go to grocery stores, libraries, coffee shops, community centers,
                etc. In Windsor it is dramatically easier to live within walking distance of public amenities, not
                because the city is particularly dense but because locations close to the amenities are affordable.
            </p>
            <p>
                I live within a 15-minute walk of my children’s school, a public library, a community center, a small
                local grocery store, a pharmacy, a coffee shop, an arena, and a community center. My kids and I walk
                to these places often. On the few days we have bad enough weather to justify driving, we never have to
                wait in traffic. Of course, these benefits can exist in the older neighborhoods of
                other cities but consider the difference in what you’d have to pay for rent or mortgage in those
                locations.
            </p>
            <a href={walktime}><img width="80%" src={walktime} className="center"></img></a>
            <p className="img-caption">Source: <a href="https://observablehq.com/@johnhaldeman/walk-times-to-minicipal-services-windsor-ontario">https://observablehq.com/@johnhaldeman/walk-times-to-minicipal-services-windsor-ontario</a></p>
            <p>
                Given all that, it’s easy to see why we moved here and have made it our home. Besides the costs of
                living and access to professional services for cross-border work, Windsor is a great place to live with
                access to a wide variety of services for whatever our needs. If you can live anywhere because your work
                allows for it, there are few places better.
            </p>
            <p style={{textAlign: "center"}}><Link to="/posts/beasts-adventure"><button>Next Post</button></Link></p>
        </>
    )
}